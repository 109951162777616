<template>
  <auth>
    <div class="right-content" id="main-content">
      <div v-show="!loading" class="register-content">
        <div v-show="step == 1">
          <h3 class="register-header">Please provide your phone number</h3>
          <hr />
          <form class="login" @submit.prevent="request" id="request-form">
            <div class="form-group">
              <label for="phone_number">Phone Number</label>

              <phone-number
                id="phone_number"
                v-model="phone_number"
                required=""
                name="phone_number"
              />
            </div>
            <div class="form-group">
              <button class="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div v-show="step == 2">
          <a
            class="btn btn-primary btn-change pull-right"
            href="#"
            @click.prevent="step = 1"
            >Change Number</a
          >
          <form @submit.prevent="verify" id="verify-form">
            <div class="form-group">
              <label>Enter the PIN we sent to {{ phone_number }}</label>
              <input
                v-model="code"
                class="form-control"
                type="text"
                name="code"
              />
            </div>
            <div class="form-group">
              <button class="btn btn-primary">
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      <loading v-if="loading" />
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  name: "verify.phone",

  components: {
    Auth,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      phone_number: "",
      step: 1,
      code: "",
      loading: true
    };
  },

  methods: {
    initialize() {
      if (!this.user.email) {
        this.$store.dispatch("initialize");
        return;
      }
      if (this.user.requires_password_change) {
        this.$router.replace({ name: "set-password" });
        return;
      }
      if (this.user.verified_phone) {
        this.$router.replace({ name: "dashboard" });
        return;
      }

      if (this.user.id_number == null && !this.user.create_by_admin) {
        this.$router.replace({ name: "verify.personal" });
      }

      this.loading = false
    },

    request() {
      this.$form.load("request-form");

      this.$axios
        .post("/api/v1/users/otp", { phone_number: this.phone_number })
        .then(() => {
          this.step = 2;
          this.loading = false
        })
        .catch((error) => {
          this.step = 1;
          this.$form.validate("request-form", error.response);
        });
    },

    verify() {
      this.$form.load('verify-form')

      let data = { code: this.code, phone_number: this.phone_number };
      this.$axios
        .post("/api/v1/verify/phone", data)
        .then(() => {
          this.$store.dispatch("initialize").then(() => {
            this.is_loaded = true;
            this.$router.replace({ name: "dashboard" });
          });
        })
        .catch((error) => {
          this.$form.validate('verify-form', error.response)
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },

  watch: {
    user: {
      handler(oldval, newval) {
        if (oldval !== newval) {
          this.initialize();
        }
      },
    },
  },
};
</script>
